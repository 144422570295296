var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "800" },
          model: {
            value: _vm.shown,
            callback: function ($$v) {
              _vm.shown = $$v
            },
            expression: "shown",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Create Job Class")]),
              _c(
                "div",
                { staticClass: "pa-4" },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      outlined: "",
                      dense: "",
                      items: _vm.projectNameItems,
                      label: "Project Name",
                    },
                    model: {
                      value: _vm.projectName,
                      callback: function ($$v) {
                        _vm.projectName = $$v
                      },
                      expression: "projectName",
                    },
                  }),
                  _c(
                    "v-expansion-panels",
                    { attrs: { value: [0, 1], multiple: "" } },
                    [
                      _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [
                            _c("p", { staticClass: "text-h6" }, [
                              _vm._v(' Job Class Parameters" '),
                            ]),
                          ]),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c(
                                "v-form",
                                [
                                  _c(
                                    "v-container",
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              disabled: !_vm.isCustomizedUrl,
                                              label: "URL",
                                              rules: [_vm.rules.required],
                                            },
                                            model: {
                                              value: _vm.jobClassParams.url,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.jobClassParams,
                                                  "url",
                                                  $$v
                                                )
                                              },
                                              expression: "jobClassParams.url",
                                            },
                                          }),
                                          _c("v-switch", {
                                            attrs: { label: "Custom" },
                                            model: {
                                              value: _vm.isCustomizedUrl,
                                              callback: function ($$v) {
                                                _vm.isCustomizedUrl = $$v
                                              },
                                              expression: "isCustomizedUrl",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("v-text-field", {
                                    staticStyle: { width: "400px" },
                                    attrs: {
                                      label: "Title",
                                      rules: [_vm.rules.required],
                                    },
                                    model: {
                                      value: _vm.jobClassParams.title,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.jobClassParams,
                                          "title",
                                          $$v
                                        )
                                      },
                                      expression: "jobClassParams.title",
                                    },
                                  }),
                                  _c(
                                    "v-container",
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c("v-checkbox", {
                                            model: {
                                              value:
                                                _vm.optionChecks.description,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.optionChecks,
                                                  "description",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "optionChecks.description",
                                            },
                                          }),
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Description",
                                              disabled:
                                                !_vm.optionChecks.description,
                                            },
                                            model: {
                                              value:
                                                _vm.jobClassParams.description,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.jobClassParams,
                                                  "description",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "jobClassParams.description",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("v-text-field", {
                                    staticStyle: { width: "300px" },
                                    attrs: {
                                      label: "Rewards(USD)",
                                      type: "number",
                                      step: "0.01",
                                      min: "0",
                                      prefix: "$",
                                      rules: [
                                        _vm.rules.required,
                                        _vm.rules.number,
                                      ],
                                    },
                                    model: {
                                      value: _vm.jobClassParams.rewards,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.jobClassParams,
                                          "rewards",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "jobClassParams.rewards",
                                    },
                                  }),
                                  _c("v-select", {
                                    attrs: {
                                      label: "Assignability",
                                      items: _vm.assignabilityItems,
                                    },
                                    model: {
                                      value: _vm.jobClassParams.assignability,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.jobClassParams,
                                          "assignability",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "jobClassParams.assignability",
                                    },
                                  }),
                                  _c(
                                    "v-container",
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c("v-checkbox", {
                                            model: {
                                              value:
                                                _vm.optionChecks.priorityScore,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.optionChecks,
                                                  "priorityScore",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "optionChecks.priorityScore",
                                            },
                                          }),
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Priority Score",
                                              type: "number",
                                              disabled:
                                                !_vm.optionChecks.priorityScore,
                                            },
                                            model: {
                                              value:
                                                _vm.jobClassParams
                                                  .priorityScore,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.jobClassParams,
                                                  "priorityScore",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression:
                                                "jobClassParams.priorityScore",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("datetime-picker", {
                                    attrs: {
                                      label: "Expires At",
                                      textFieldProps: {
                                        "prepend-icon": "mdi-calendar",
                                        rules: [_vm.rules.required],
                                      },
                                    },
                                    model: {
                                      value: _vm.jobClassParams.expiredAt,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.jobClassParams,
                                          "expiredAt",
                                          $$v
                                        )
                                      },
                                      expression: "jobClassParams.expiredAt",
                                    },
                                  }),
                                  _c(
                                    "v-container",
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c("v-checkbox", {
                                            model: {
                                              value: _vm.optionChecks.closedAt,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.optionChecks,
                                                  "closedAt",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "optionChecks.closedAt",
                                            },
                                          }),
                                          _c("datetime-picker", {
                                            staticStyle: { width: "300px" },
                                            attrs: {
                                              label: "Closed At",
                                              disabled:
                                                !_vm.optionChecks.closedAt,
                                              textFieldProps: {
                                                "prepend-icon": "mdi-calendar",
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.jobClassParams.closedAt,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.jobClassParams,
                                                  "closedAt",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "jobClassParams.closedAt",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("v-text-field", {
                                    staticStyle: { width: "300px" },
                                    attrs: {
                                      label: "Time Limit",
                                      type: "time",
                                      step: "1",
                                      rules: [_vm.rules.required],
                                    },
                                    model: {
                                      value: _vm.jobClassParams.timeLimit,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.jobClassParams,
                                          "timeLimit",
                                          $$v
                                        )
                                      },
                                      expression: "jobClassParams.timeLimit",
                                    },
                                  }),
                                  _c("v-select", {
                                    staticStyle: { width: "400px" },
                                    attrs: {
                                      label: "Target Worker/Worker Group IDs",
                                      multiple: "",
                                      attach: "",
                                      chips: "",
                                      items: _vm.targetPartyIds,
                                      rules: [_vm.rules.notEmpty],
                                    },
                                    model: {
                                      value:
                                        _vm.jobClassParams.target_party_ids,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.jobClassParams,
                                          "target_party_ids",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "jobClassParams.target_party_ids",
                                    },
                                  }),
                                  _c("v-select", {
                                    staticStyle: { width: "400px" },
                                    attrs: {
                                      label: "Job Progress Data Type",
                                      items: _vm.jobProgressDataType,
                                      rules: [_vm.rules.required],
                                    },
                                    model: {
                                      value:
                                        _vm.jobClassParams.jobProgressDataType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.jobClassParams,
                                          "jobProgressDataType",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "jobClassParams.jobProgressDataType",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [
                            _c("p", { staticClass: "text-h6" }, [
                              _vm._v(" Parameter Sets (Optional) "),
                            ]),
                          ]),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c("b", [_vm._v("Platform Parameter Set")]),
                              _c("v-checkbox", {
                                attrs: {
                                  label:
                                    "Generate platform parameter set for this job class",
                                },
                                model: {
                                  value: _vm.optionChecks.platformParamSet,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.optionChecks,
                                      "platformParamSet",
                                      $$v
                                    )
                                  },
                                  expression: "optionChecks.platformParamSet",
                                },
                              }),
                              _vm.optionChecks.platformParamSet
                                ? _c(
                                    "div",
                                    [
                                      _c("v-text-field", {
                                        attrs: { label: "Name (optional)" },
                                        model: {
                                          value:
                                            _vm.platformParamSetParams.name,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.platformParamSetParams,
                                              "name",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "platformParamSetParams.name",
                                        },
                                      }),
                                      _c("v-text-field", {
                                        attrs: {
                                          type: "number",
                                          label:
                                            "num_job_assignments_max (Blank = null)",
                                        },
                                        model: {
                                          value:
                                            _vm.platformParamSetParams
                                              .num_job_assignments_max,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.platformParamSetParams,
                                              "num_job_assignments_max",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression:
                                            "platformParamSetParams.num_job_assignments_max",
                                        },
                                      }),
                                      _c("v-text-field", {
                                        attrs: {
                                          type: "number",
                                          label: "priorityScore (Blank = 100)",
                                        },
                                        model: {
                                          value:
                                            _vm.platformParamSetParams
                                              .priorityScore,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.platformParamSetParams,
                                              "priorityScore",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression:
                                            "platformParamSetParams.priorityScore",
                                        },
                                      }),
                                      _c(
                                        "p",
                                        {
                                          staticClass: "mt-6",
                                          staticStyle: {
                                            "font-weight": "bold",
                                          },
                                        },
                                        [_vm._v("Automation ID")]
                                      ),
                                      _c("v-checkbox", {
                                        attrs: {
                                          label: "Generate automation ID",
                                        },
                                        model: {
                                          value:
                                            _vm.optionChecks
                                              .automationParameterSet,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.optionChecks,
                                              "automationParameterSet",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "optionChecks.automationParameterSet",
                                        },
                                      }),
                                      _vm.optionChecks.automationParameterSet
                                        ? _c(
                                            "div",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: "Name (optional)",
                                                },
                                                model: {
                                                  value:
                                                    _vm
                                                      .automationParameterSetParams
                                                      .name,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.automationParameterSetParams,
                                                      "name",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "automationParameterSetParams.name",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("tutti-dialog-actions", {
                attrs: {
                  actions: [
                    {
                      label: "submit",
                      text: true,
                      color: "indigo",
                      callback: _vm.submit,
                    },
                  ],
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("tutti-snackbar", { ref: "snackbar" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }