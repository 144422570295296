var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c("page-toolbar", {
            attrs: {
              "previous-page-label": "Tutti Market Top",
              title: "Job Classes",
            },
            scopedSlots: _vm._u([
              {
                key: "contents",
                fn: function () {
                  return [
                    _c(
                      "v-btn",
                      {
                        attrs: { text: "", color: "indigo" },
                        on: { click: _vm.showCreateJobClassDialog },
                      },
                      [
                        _c("v-icon", { attrs: { left: "" } }, [
                          _vm._v("mdi-plus"),
                        ]),
                        _vm._v(" Create Job Classes "),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { md: "10", cols: "12" } },
        [
          _c(
            "v-card",
            [
              _c("job-class-table", {
                ref: "jobClassTable",
                attrs: { market: _vm.market },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("dialog-create-job-class", {
        ref: "dialogCreateJobClass",
        attrs: {
          projectName: _vm.projectName,
          client: _vm.client,
          market: _vm.market,
        },
        on: {
          "create-job-class": _vm.refreshJobClassTable,
          "create-parameter-sets": _vm.showParameterSetIdsDialog,
        },
      }),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600" },
          model: {
            value: _vm.shown.dialogParameterSetIds,
            callback: function ($$v) {
              _vm.$set(_vm.shown, "dialogParameterSetIds", $$v)
            },
            expression: "shown.dialogParameterSetIds",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c("v-icon", { attrs: { color: "success" } }, [
                    _vm._v("mdi-check-circle"),
                  ]),
                  _vm._v(" Successfully generated parameter sets "),
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _vm._v(" Platform parameter set ID: "),
                  _c("v-text-field", {
                    staticClass: "mb-3",
                    attrs: {
                      outlined: "",
                      "hide-details": "",
                      readonly: "",
                      dense: "",
                      value: _vm.ppsid,
                    },
                  }),
                  _c(
                    "span",
                    { staticStyle: { "font-weight": "bold", color: "red" } },
                    [_vm._v("Automation ID")]
                  ),
                  _vm._v(": "),
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      "hide-details": "",
                      readonly: "",
                      dense: "",
                      value: _vm.apsid,
                    },
                  }),
                ],
                1
              ),
              _c("tutti-dialog-actions", {
                attrs: {
                  actions: [
                    {
                      label: "close",
                      callback: () => {
                        _vm.shown.dialogParameterSetIds = false
                      },
                    },
                  ],
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }