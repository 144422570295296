<template>
    <v-row justify="center">
        <v-col cols="12">
            <page-toolbar previous-page-label="Tutti Market Top" title="Job Classes">
                <template #contents>
                    <v-btn text color="indigo" @click="showCreateJobClassDialog">
                        <v-icon left>mdi-plus</v-icon>
                        Create Job Classes
                    </v-btn>
                </template>
            </page-toolbar>
        </v-col>

        <v-col md="10" cols="12">
            <v-card>
                <job-class-table ref="jobClassTable" :market="market" />
            </v-card>
        </v-col>

        <dialog-create-job-class
            ref="dialogCreateJobClass"
            :projectName="projectName"
            :client="client"
            :market="market"
            @create-job-class="refreshJobClassTable"
            @create-parameter-sets="showParameterSetIdsDialog"
        />

        <v-dialog v-model="shown.dialogParameterSetIds" max-width="600">
            <v-card>
                <v-card-title>
                    <v-icon color="success">mdi-check-circle</v-icon>
                    Successfully generated parameter sets
                </v-card-title>
                <v-card-text>
                    Platform parameter set ID:
                    <v-text-field
                        outlined
                        hide-details
                        readonly
                        dense
                        :value="ppsid"
                        class="mb-3"
                    />
                    <span style="font-weight:bold;color:red;">Automation ID</span>:
                    <v-text-field
                        outlined
                        hide-details
                        readonly
                        dense
                        :value="apsid"
                    />
                </v-card-text>
                <tutti-dialog-actions
                    :actions="[
                        {
                            label: 'close',
                            callback: () => { shown.dialogParameterSetIds = false; }
                        },
                    ]"
                />
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import PageToolbar from '@/components/ui/PageToolbar'
import JobClassTable from './JobClassTable'
import DialogCreateJobClass from './DialogCreateJobClass'
import TuttiDialogActions from '@/components/ui/TuttiDialogActions'

export default {
    components: {
        PageToolbar,
        JobClassTable,
        DialogCreateJobClass,
        TuttiDialogActions
    },
    data: () => ({
        shown: {
            dialogParameterSetIds: false,
        },
        ppsid: 'myppsid',
        apsid: 'myapsid'
    }),
    props: ['projectName', 'market', 'client'],
    methods: {
        openCreateJobClassDialog(){
            this.$refs.dialogCreateJobClass.shown = true;
        },
        refreshJobClassTable() {
            this.$refs.jobClassTable.refreshJobClassTable();
        },
        showParameterSetIdsDialog(ppsid, apsid) {
            this.ppsid = ppsid;
            this.apsid = apsid;
            this.shown.dialogParameterSetIds = true;
        },
        showCreateJobClassDialog() {
            this.$refs.dialogCreateJobClass.shown = true;
        }
    },
}
</script>
