<template>
    <div>
        <v-dialog v-model="shown" max-width="800">
            <v-card>
                <v-card-title>Create Job Class</v-card-title>

                <div class="pa-4">
                    <v-autocomplete outlined dense v-model="projectName" :items="projectNameItems" label="Project Name" />
                    <v-expansion-panels :value="[0,1]" multiple>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <p class="text-h6">
                                    Job Class Parameters"
                                </p>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-form>
                                    <v-container>
                                        <v-row>
                                            <v-text-field
                                                :disabled="!isCustomizedUrl"
                                                v-model="jobClassParams.url"
                                                label="URL"
                                                :rules="[rules.required]"
                                            />
                                            <v-switch label="Custom" v-model="isCustomizedUrl" />
                                        </v-row>
                                    </v-container>
                                    <v-text-field
                                        style="width:400px"
                                        v-model="jobClassParams.title"
                                        label="Title"
                                        :rules="[rules.required]"
                                    />

                                    <v-container>
                                        <v-row>
                                            <v-checkbox v-model="optionChecks.description" />
                                            <v-text-field
                                                v-model="jobClassParams.description"
                                                label="Description"
                                                :disabled="!optionChecks.description"
                                            />
                                        </v-row>
                                    </v-container>

                                    <v-text-field
                                        style="width:300px"
                                        v-model.number="jobClassParams.rewards"
                                        label="Rewards(USD)"
                                        type="number"
                                        step="0.01"
                                        min="0"
                                        prefix="$"
                                        :rules="[rules.required, rules.number]"
                                    />

                                    <v-select label="Assignability" :items="assignabilityItems" v-model="jobClassParams.assignability" />

                                    <v-container>
                                        <v-row>
                                            <v-checkbox v-model="optionChecks.priorityScore" />
                                            <v-text-field
                                                v-model.number="jobClassParams.priorityScore"
                                                label="Priority Score"
                                                type="number"
                                                :disabled="!optionChecks.priorityScore"
                                            />
                                        </v-row>
                                    </v-container>

                                    <datetime-picker
                                        label="Expires At"
                                        v-model="jobClassParams.expiredAt"
                                        :textFieldProps="{
                                            'prepend-icon': 'mdi-calendar',
                                            rules: [rules.required],
                                        }"
                                    />

                                    <v-container>
                                        <v-row>
                                            <v-checkbox v-model="optionChecks.closedAt" />
                                            <datetime-picker
                                                style="width:300px"
                                                label="Closed At"
                                                v-model="jobClassParams.closedAt"
                                                :disabled="!optionChecks.closedAt"
                                                :textFieldProps="{ 'prepend-icon': 'mdi-calendar' }"
                                            />
                                        </v-row>
                                    </v-container>

                                    <v-text-field
                                        v-model="jobClassParams.timeLimit"
                                        style="width:300px"
                                        label="Time Limit"
                                        type="time"
                                        step="1"
                                        :rules="[rules.required]"
                                    />

                                    <v-select
                                        v-model="jobClassParams.target_party_ids"
                                        style="width:400px"
                                        label="Target Worker/Worker Group IDs"
                                        multiple attach chips
                                        :items="targetPartyIds"
                                        :rules="[rules.notEmpty]"
                                    />

                                    <v-select
                                        v-model="jobClassParams.jobProgressDataType"
                                        style="width:400px"
                                        label="Job Progress Data Type"
                                        :items="jobProgressDataType"
                                        :rules="[rules.required]"
                                    />
                                </v-form>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <p class="text-h6">
                                    Parameter Sets (Optional)
                                </p>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <b>Platform Parameter Set</b>
                                <v-checkbox
                                    v-model="optionChecks.platformParamSet"
                                    label="Generate platform parameter set for this job class"
                                />
                                <div v-if="optionChecks.platformParamSet">
                                    <v-text-field
                                        v-model="platformParamSetParams.name"
                                        label="Name (optional)"
                                    />
                                    <v-text-field
                                        v-model.number="platformParamSetParams.num_job_assignments_max"
                                        type="number"
                                        label="num_job_assignments_max (Blank = null)"
                                    />
                                    <v-text-field
                                        v-model.number="platformParamSetParams.priorityScore"
                                        type="number"
                                        label="priorityScore (Blank = 100)"
                                    />
                                    <p style="font-weight:bold" class="mt-6">Automation ID</p>
                                    <v-checkbox
                                        v-model="optionChecks.automationParameterSet"
                                        label="Generate automation ID"
                                    />
                                    <div v-if="optionChecks.automationParameterSet">
                                        <v-text-field
                                            v-model="automationParameterSetParams.name"
                                            label="Name (optional)"
                                        />
                                    </div>
                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </div>
                
                <tutti-dialog-actions
                    :actions="[
                        {
                            label: 'submit',
                            text: true,
                            color: 'indigo',
                            callback: submit,
                        }
                    ]"
                />
            </v-card>
        </v-dialog>

        <tutti-snackbar ref="snackbar" />
    </div>
</template>
<script>
import DatetimePicker from '@/components/ui/DatetimePicker'
import TuttiSnackbar from '@/components/ui/TuttiSnackbar'
import TuttiDialogActions from '@/components/ui/TuttiDialogActions'
import marketConsts from '@/lib/market/constants'
import rules from '@/lib/input-rules'
import { getUrl } from '@/lib/tutti-env'

const jobClassParamsDefault = {
    url: '',
    title: 'TEAI Task (for everyone): Annotation of a 10-min video',
    description: 'Give notes and scores to utterances of a student.',
    rewards: 5,
    assignability: Object.values(marketConsts.jobAssignability)[0],
    priorityScore: 100,
    expiredAt: new Date('2022-03-01 23:59'),
    closedAt: new Date('2022-03-01 23:59'),
    timeLimit: '02:00:00',
    target_party_ids: ['all'],
    jobProgressDataType: 'no_data',
};

const optionChecksDefault = {
    description: true,
    priorityScore: true,
    closedAt: true,
};

export default {
    components: {
        DatetimePicker,
        TuttiSnackbar,
        TuttiDialogActions,
    },
    data: () => ({
        rules,

        shown: false,
        pickerShown: {
            expiredAt: false,
            closedAt: false,
        },

        targetPartyIds: [],

        jobClassParams: {
            url: '',
            title: '',
            description: null,
            rewards: 0,
            expiredAt: '',
            closedAt: null,
            timeLimit: null,
            target_party_ids: [],
            assignability: Object.values(marketConsts.jobAssignability)[0],
            priorityScore: null,
            jobProgressDataType: '',
        },
        isCustomizedUrl: false,
        platformParamSetParams: {
            name: null,
            num_job_assignments_max: null,
            priorityScore: null
        },
        automationParameterSetParams: {
            name: null,
        },
        optionChecks: {
            description: false,
            priorityScore: false,
            closedAt: false,
            platformParamSet: false,
            automationParameterSet: false,
        },
        assignabilityItems: Object.values(marketConsts.jobAssignability),
        projectName: '',
        projectNameItems: [],
    }),
    props: ['client', 'market'],
    computed: {
        jobProgressDataType() {
            return Object.entries(marketConsts.jobProgressDataType).map(([, val]) => (val));
        },
    },
    methods: {
        async createJobClass() {
            const jobClassId = await this.market.createJobClass(
                    this.jobClassParams.url,
                    this.jobClassParams.title,
                    {},
                    this.jobClassParams.description,
                    parseInt(this.jobClassParams.rewards*100),
                    this.jobClassParams.expiredAt,
                    this.jobClassParams.closedAt,
                    new Date(
                        this.jobClassParams.timeLimit.split(':')
                        .reduce((a,c,i) => (a+parseInt(c)*Math.pow(60,2-i)), 0)
                        *1000
                    ),
                    this.jobClassParams.target_party_ids,
                    this.jobClassParams.assignability,
                    this.jobClassParams.priorityScore,
                    this.jobClassParams.jobProgressDataType
                );
            await this.market.openJobClass(jobClassId);
            this.$refs.snackbar.show('success', 'Successfully created job class');
            this.$emit('create-job-class');
            return jobClassId;
        },
        async createParameterSetsIfRequested(jobClassId) {
            if(this.optionChecks.platformParamSet) {
                const ppsid = await this.createPlatformParameterSet(jobClassId);
                if(this.optionChecks.automationParameterSet) {
                    const apsid = await this.createAutomationParameterSet(ppsid);
                    this.$emit('create-parameter-sets', ppsid, apsid);
                } else {
                    this.$emit('create-parameter-sets', ppsid);
                }
            }
        },
        async createPlatformParameterSet(jobClassId) {
            return await this.client.resource.createPlatformParameterSet({
                    name: this.platformParamSetParams.name,
                    platform: 'market',
                    parameters: {
                        job_class_id: jobClassId,
                        num_job_assignments_max: this.platformParamSetParams.num_job_assignments_max,
                        priorityScore: this.platformParamSetParams.priorityScore
                    }
                });
        },
        async createAutomationParameterSet(ppsid) {
            return await this.client.resource.createAutomationParameterSet({
                    name: this.automationParameterSetParams.name,
                    platform_parameter_set_id: ppsid,
                    project_name: this.projectName,
                });
        },
        async submit() {
            const jobClassId = await this.createJobClass();
            await this.createParameterSetsIfRequested(jobClassId);
            this.shown = false;
        }
    },
    watch: {
        optionChecks: {
            deep: true,
            handler(checks) {
                Object.entries(checks).forEach(([key, chk]) => {
                    if(!chk) { this.jobClassParams[key] = null; }
                });
            }
        },
        'pickerShown.expiredAtTime'(val) {
            if(val) this.pickerShown.expiredAtDate = true;
            else {
                this.jobClassParams.expiredAt = `${this.jobClassParams.expiredAtDate} ${this.jobClassParams.expiredAtTime}`;
            }
        },
        projectName(val) {
            this.jobClassParams.url = getUrl(val, true);
        }
    },
    mounted() {
        this.client.invokeOnOpen(async () => {
            const projects = await this.client.resource.listProjects()
            this.projectNameItems = projects.map(p => p.name);
        });
        console.log(this.client);
        this.jobClassParams = { ...jobClassParamsDefault };
        this.optionChecks = { ...optionChecksDefault };
        this.market.invokeOnOpen(async () => {
            const workerGroupIds = await this.market.getWorkerGroupIds();
            const workerIds = await this.market.getUserIds([
                    marketConsts.userRole.worker,
                    marketConsts.userRole.administrator
                ]);
            this.targetPartyIds = [
                ...workerGroupIds.map((wgid) => ({ text: `[Worker Group] ${wgid}`, value: wgid })),
                ...workerIds.map((wid) => ({ text: `[Worker] ${wid}`, value: wid })),
            ];
        });
    }
}
</script>
