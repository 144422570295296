var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-data-table", {
        attrs: {
          "sort-desc": "",
          "show-expand": "",
          headers: _vm.headers,
          items: _vm.jobClasses,
          "sort-by": "id",
          search: _vm.searchQuery,
        },
        scopedSlots: _vm._u(
          [
            {
              key: "top",
              fn: function () {
                return [
                  _c(
                    "v-card-title",
                    [
                      _c("v-spacer"),
                      _c("v-text-field", {
                        attrs: {
                          "single-line": "",
                          "hide-details": "",
                          "append-icon": "mdi-magnify",
                          label: "Search",
                        },
                        model: {
                          value: _vm.searchQuery,
                          callback: function ($$v) {
                            _vm.searchQuery = $$v
                          },
                          expression: "searchQuery",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            _vm._l(Object.entries(_vm.cellFormats), function ([col, format]) {
              return {
                key: `item.${col}`,
                fn: function ({ item }) {
                  return [
                    _c("span", {
                      key: col,
                      domProps: { innerHTML: _vm._s(format(item)) },
                    }),
                  ]
                },
              }
            }),
            {
              key: "expanded-item",
              fn: function ({ headers, item }) {
                return [
                  _c(
                    "td",
                    { staticClass: "pa-2", attrs: { colspan: headers.length } },
                    [
                      _c("v-simple-table", { attrs: { dense: "" } }, [
                        _c(
                          "tbody",
                          _vm._l(Object.entries(item), function ([col, val]) {
                            return _c("tr", { key: `ite-${col}` }, [
                              _c("th", [_vm._v(_vm._s(col))]),
                              _c("td", [_vm._v(_vm._s(val))]),
                            ])
                          }),
                          0
                        ),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "item.actions",
              fn: function ({ item }) {
                return [
                  _c(
                    "v-icon",
                    {
                      attrs: { small: "" },
                      on: {
                        click: function ($event) {
                          _vm.deleteJobClassId = item.id
                          _vm.shown.dialogDeleteJobClass = true
                        },
                      },
                    },
                    [_vm._v("mdi-trash-can-outline")]
                  ),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400" },
          model: {
            value: _vm.shown.dialogDeleteJobClass,
            callback: function ($$v) {
              _vm.$set(_vm.shown, "dialogDeleteJobClass", $$v)
            },
            expression: "shown.dialogDeleteJobClass",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v("Delete Job Class " + _vm._s(_vm.deleteJobClassId)),
              ]),
              _c("v-card-text", [
                _vm._v(
                  " Really delete job class " +
                    _vm._s(_vm.deleteJobClassId) +
                    "? This cannot be undone. "
                ),
              ]),
              _c("tutti-dialog-actions", {
                attrs: { actions: _vm.deleteJobClassActions },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }