var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: _vm.dialogWidth },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-text-field",
                _vm._g(
                  _vm._b(
                    {
                      attrs: {
                        disabled: _vm.disabled,
                        loading: _vm.loading,
                        label: _vm.label,
                        value: _vm.formattedDatetime,
                        readonly: "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "progress",
                            fn: function () {
                              return [
                                _vm._t("progress", function () {
                                  return [
                                    _c("v-progress-linear", {
                                      attrs: {
                                        color: "indigo",
                                        indeterminate: "",
                                        absolute: "",
                                        height: "2",
                                      },
                                    }),
                                  ]
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    "v-text-field",
                    _vm.textFieldProps,
                    false
                  ),
                  on
                )
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.display,
        callback: function ($$v) {
          _vm.display = $$v
        },
        expression: "display",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            { staticClass: "px-0 py-0" },
            [
              _c(
                "v-tabs",
                {
                  attrs: { "fixed-tabs": "" },
                  model: {
                    value: _vm.activeTab,
                    callback: function ($$v) {
                      _vm.activeTab = $$v
                    },
                    expression: "activeTab",
                  },
                },
                [
                  _c(
                    "v-tab",
                    { key: "calendar" },
                    [
                      _vm._t("dateIcon", function () {
                        return [
                          _c("v-icon", { attrs: { color: "indigo" } }, [
                            _vm._v("mdi-calendar"),
                          ]),
                        ]
                      }),
                    ],
                    2
                  ),
                  _c(
                    "v-tab",
                    { key: "timer", attrs: { disabled: _vm.dateSelected } },
                    [
                      _vm._t("timeIcon", function () {
                        return [
                          _c("v-icon", { attrs: { color: "indigo" } }, [
                            _vm._v("mdi-clock"),
                          ]),
                        ]
                      }),
                    ],
                    2
                  ),
                  _c(
                    "v-tab-item",
                    { key: "calendar" },
                    [
                      _c(
                        "v-date-picker",
                        _vm._b(
                          {
                            attrs: { color: "indigo", "full-width": "" },
                            on: { input: _vm.showTimePicker },
                            model: {
                              value: _vm.date,
                              callback: function ($$v) {
                                _vm.date = $$v
                              },
                              expression: "date",
                            },
                          },
                          "v-date-picker",
                          _vm.datePickerProps,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { key: "timer" },
                    [
                      _c(
                        "v-time-picker",
                        _vm._b(
                          {
                            ref: "timer",
                            staticClass: "v-time-picker-custom",
                            attrs: { color: "indigo", "full-width": "" },
                            model: {
                              value: _vm.time,
                              callback: function ($$v) {
                                _vm.time = $$v
                              },
                              expression: "time",
                            },
                          },
                          "v-time-picker",
                          _vm.timePickerProps,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._t(
                "actions",
                function () {
                  return [
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "grey lighten-1", text: "" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.clearHandler.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.clearText))]
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "indigo darken-1", text: "" },
                        on: { click: _vm.okHandler },
                      },
                      [_vm._v(_vm._s(_vm.okText))]
                    ),
                  ]
                },
                { parent: this }
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }