<template>
    <div>
        <v-data-table
            sort-desc
            show-expand
            :headers="headers"
            :items="jobClasses"
            sort-by="id"
            :search="searchQuery"
        >
            <template v-slot:top>
                <v-card-title>
                    <v-spacer></v-spacer>
                    <v-text-field
                        single-line
                        hide-details 
                        v-model="searchQuery"
                        append-icon="mdi-magnify"
                        label="Search" />
                </v-card-title>
            </template>
            <template v-for="[col, format] of Object.entries(cellFormats)" v-slot:[`item.${col}`]="{ item }">
                <span :key="col" v-html="format(item)" />
            </template>
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="pa-2">
                    <v-simple-table dense>
                        <tbody>
                            <tr v-for="[col, val] in Object.entries(item)" :key="`ite-${col}`">
                                <th>{{ col }}</th>
                                <td>{{ val }}</td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </td>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon small @click="deleteJobClassId = item.id; shown.dialogDeleteJobClass = true;">mdi-trash-can-outline</v-icon>
            </template>
        </v-data-table>
        <v-dialog v-model="shown.dialogDeleteJobClass" max-width="400">
            <v-card>
                <v-card-title>Delete Job Class {{ deleteJobClassId }}</v-card-title>
                <v-card-text>
                    Really delete job class {{ deleteJobClassId }}? This cannot be undone.
                </v-card-text>
                <tutti-dialog-actions
                    :actions="deleteJobClassActions"
                />
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import dateFormat from 'dateformat'
import TuttiDialogActions from '@/components/ui/TuttiDialogActions'

export default {
    components: {
        TuttiDialogActions,
    },
    data: () => ({
        dateFormat,
        shown: {
            dialogDeleteJobClass: false,
        },
        jobClasses: [],
        headers: [],
        auxColumns: {
            open: (jc) => (
                    jc.expired_at < new Date().getTime()
                ),
        },
        cellFormats: {
            rewards: (item) => ('$'+(item.rewards/100).toFixed(2)),
            expired_at: (item) => (dateFormat(item.expired_at, 'yyyy-mm-dd hh:MM:ss')),
            deleted_at: (item) => (dateFormat(item.deleted_at, 'yyyy-mm-dd hh:MM:ss')),
            time_limit: (item) => {
                    let seconds = item.time_limit/1000;
                    let strs = [];
                    if(seconds>=60*60) { strs.push(`${seconds/(60*60)}h`); seconds -= (seconds/(60*60))*60*60 }
                    if(seconds>=60) { strs.push(`${seconds/(60)}m`); seconds -= (seconds/(60))*60 }
                    if(seconds>0) { strs.push(`${seconds}s`); }
                    return strs.join(' ');
                },
            is_reassignable: (item) => (item.is_reassignable ? '<span class="mdi mdi-check" />' : ''),
            open: (item) => (item.open ? '<span class="mdi mdi-circle" style="color:#4caf50" />' : ''),
        },
        columnInfo: {
            open:     { showHeader: true, text: 'Open' },
            id:          { showHeader: true, text: 'ID' },
            title:       { showHeader: true, text: 'Title' },
            rewards:     { showHeader: true, text: 'Reward' },
            expired_at:  { showHeader: true, text: 'Expires at' },
            deleted_at:  { showHeader: true, text: 'Closes at' },
            time_limit:  { showHeader: true, text: 'Time limit' },
            is_reassignable:  { showHeader: true, text: 'Reassignable' },
        },

        deleteJobClassId: null,

        searchQuery: '',
    }),
    props: ['market'],
    computed: {
        deleteJobClassActions() {
            return [
                {
                    label: 'cancel',
                    callback: () => { this.shown.dialogDeleteJobClass = false; }
                },
                {
                    label: 'delete',
                    color: 'error',
                    callback: async () => {
                        await this.deleteJobClass();
                        this.shown.dialogDeleteJobClass = false;
                        await this.refreshJobClassTable();
                    }
                }
            ]
        }
    },
    methods: {
        async getJobClasses() {
            const jobClassIds = await this.market.getJobClassIds();
            const getJobClassPromises = jobClassIds.map(async (jcid) => ({ id: jcid, ...(await this.market.getJobClass(jcid)) }));
            let jobClasses = (await Promise.all(getJobClassPromises));
            jobClasses.forEach(async (jc,i) => {
                jobClasses[i].open = await this.market.isJobClassOpen(jc.id);
            });

            this.jobClasses = jobClasses;

            console.log(this.jobClasses);
        },
        getHeadersFromData() {
            if(this.jobClasses.length>0) {
                let headers = [
                    { text: '', value: 'data-table-expand', order: -2 },
                    { text: 'Open', value: 'open', order: -1 },
                    ...Object.keys(this.jobClasses[0])
                        .filter((header) => ((header in this.columnInfo) && this.columnInfo[header].showHeader))
                        .map((header) => ({
                            text: this.columnInfo[header].text || header,
                            value: header,
                            order: Object.keys(this.columnInfo).indexOf(header)
                        }))
                ];
                this.headers = [ ...headers.sort((a,b) => (a.order>b.order ? 1 : -1)), { text: '', value: 'actions' }];
            }
        },
        async refreshJobClassTable() {
            await this.getJobClasses();
            this.getHeadersFromData();
        },
        async deleteJobClass() {
            await this.market.deleteJobClass(this.deleteJobClassId);
        }
    },
    async mounted() {
        this.market.invokeOnOpen(async () => {
            this.refreshJobClassTable();
        });
    }
}
</script>
